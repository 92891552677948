
















































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItems } from '@/store/main/actions';

@Component
export default class Dashboard extends Vue {
  // get greetedUser() {
  //   const userProfile = readUserProfile(this.$store);
  //   if (userProfile) {
  //     if (userProfile.full_name) {
  //       return userProfile.full_name;
  //     } else {
  //       return userProfile.email;
  //     }
  //   }
  // }

  // public switchUSDT = false;
  // public tokenType = 'TWD';
  // public switchDisplay() {
  //   if (this.switchUSDT === true) {
  //     this.tokenType = 'USDT';
  //     return '以 USDT 計算收益與 APR';
  //   } else {
  //     this.tokenType = '新臺幣';
  //     return '以 新臺幣 計算收益與 APR';
  //   }
  // }

  // my edit
  public headers = [
    { text: '起始時間', sortable: true, value: 'time_started', align: 'left' },
    { text: '方案', sortable: true, value: 'title', align: 'left'},
    { text: '起始資產', sortable: true, value: 'initial_value', align: 'left'},
    { text: '現在資產', sortable: true, value: 'current_value', align: 'left'},
    // { text: '收益', sortable: true, value: 'gains', align: 'left'},
    { text: 'APR', sortable: true, value: 'apr', align: 'left'},
    { text: 'profit (plan)', sortable: true, value: 'profit_plan', align: 'left'},
    { text: 'profit (invite)', sortable: true, value: 'profit_invite', align: 'left'},
    { text: 'used code', sortable: true, value: 'used_code', align: 'left'},
    { text: '狀態', sortable: true, value: 'status', align: 'left'},
    { text: '約期', sortable: true, value: 'duration', align: 'left'},
    { text: 'User ID', sortable: true, value: 'owner_id', align: 'left'},
    { text: 'Item ID', sortable: true, value: 'id', align: 'left'},
    { text: 'Address', sortable: true, value: 'address', align: 'left'},
    { text: 'Ending Email', sortable: true, value: 'ending_email_sent', align: 'left'},
  ];
  
  get userItems() {
    return readUserItems(this.$store);
  }

  public async mounted() {
    await dispatchGetUserItems(this.$store);
  }
}
